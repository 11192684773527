<template>
  <v-row class="justify-center">
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="pricing in pricingContent"
      :key="pricing.id"
    >
      <div
        class="rn-pricing poss_relative pricing-height"
        :class="{ active: pricing.active }"
      >
        <div class="pricing-table-inner">
          <div class="pricing-header">
            <h4 class="heading-title">{{ pricing.title }}</h4>
            <small
              v-if="pricing.especial == true"
              class="text-decoration-line-through poss_absolute"
              style="left: 43%; top: 15%"
              >$ {{ pricing.precioRegular }}</small
            >
            <div class="pricing">
              <span class="price">${{ pricing.price }}</span>
              <!-- <span v-if="pricing.especial == true"> En especial !</span> -->
              <span class="subtitle">{{ pricing.subtitle }}</span>
            </div>
          </div>

          <div class="pricing-body">
            <ul class="list-style--1">
              <li v-for="(singleList, i) in pricing.listItem" :key="i">
                <i v-html="iconSvg(icon)"></i>{{ singleList }}
              </li>
            </ul>
          </div>

          <!-- <div class="poss_absolute backpanel" v-if="pricing.comingSoon">
            <div class="coming-soon">Coming Soon</div>
          </div> -->

          <div class="pricing-footer">
            <a class="rn-btn" @click="Select(pricing.id, pricing.price)">{{
              pricing.btn
            }}</a>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      icon: "check",
      pricingContent: [
        // {
        //   id: 1,
        //   title: "Deluxe",
        //   price: 45,
        //   subtitle: "Pago Mensual",
        //   active: false,
        //   btn: "Compre Ahora",
        //   listItem: [
        //     {
        //       list: "Creación de tu propia pagina",
        //     },
        //     {
        //       list: "1 plantilla",
        //     },
        //     {
        //       list: "Predicaciones y Ministerios",
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   title: "Deluxe Pro",
        //   price: 75,
        //   subtitle: "Pago Mensual",
        //   active: true,
        //   btn:"Compre Ahora",
        //   listItem: [
        //     {
        //       list: "Caracteristicas del Deluxe",
        //     },
        //     {
        //       list: "Video en portada",
        //     },
        //     {
        //       list: "2 plantillas",
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   title: "Ultimate",
        //   price: 125,
        //   subtitle: "Pago Mensual",
        //   active: false,
        //   btn:"Comuniquese con nosotros",
        //   comingSoon: true,
        //   listItem: [
        //     {
        //       list: "Caracteristicas del Deluxe Pro",
        //     },
        //     {
        //       list: "Agenda",
        //     },
        //     {
        //       list: "Calendario",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    this.getPackeges();
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    Select(id, pago) {
      // this.$store.state.pricingPlan = id;
      // this.$store.state.pricinPago = pago;
      this.$store.commit("set_Pricing", { id: id, pricinPago: pago });
      console.log(pago);
      this.$router.push({ name: "Crear" });
    },
    getPackeges() {
      this.$store.dispatch("get_Paquetes").then((res) => {
        // {
        //   id: 1,
        //   title: "Deluxe",
        //   price: 45,
        //   subtitle: "Pago Mensual",
        //   active: false,
        //   btn:"Compre Ahora",
        //   listItem: [
        //     {
        //       list: "Creación de tu propia pagina",
        //     },
        //     {
        //       list: "1 plantilla",
        //     },
        //     {
        //       list: "Predicaciones y Ministerios",
        //     },

        //   ],
        // },
        res.forEach((element) => {
          let bullet_split = element.Bullets.split(";");

          this.pricingContent.push({
            id: element.Id,
            title: element.Nombre_Paquete,
            price:
              element.Especial == true
                ? element.Precio_Especial
                : element.Precio,
            subtitle: "Pago Mensual",
            active: false,
            btn: "Compre Ahora",
            listItem: bullet_split,
            especial: element.Especial,
            precioRegular: element.Precio,
          });
        });
        this.pricingContent[1].active = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pricing-height {
  height: 530px;
}
.backpanel {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
}
.coming-soon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  // width: 100px;
  font-size: 36px;
  background-color: rgba(255, 255, 255, 0.082);
  text-align: center;
  transform: rotate(-45deg);
  z-index: 10;
  width: 300px;
  color: #58df71;
  border: 2px solid #e04d4d;
  border-radius: 8px;
}
</style>